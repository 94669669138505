import React, { Component } from "react"
import "./footer.scss"

class Footer extends Component {
  render() {
    return <footer></footer>
  }
}

export default Footer
